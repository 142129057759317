import * as React from 'react';
import cn from 'classnames';

import RadioTab from './RadioTab';

import css from './index.scss';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const RadioTabs = ({ className, children }: Props) => (
  <div className={cn(css.tabs, className)}>{children}</div>
);

RadioTabs.Tab = RadioTab;

export default RadioTabs;
