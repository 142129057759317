import * as React from 'react';

interface Props extends React.FormHTMLAttributes<HTMLFormElement> {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void | boolean;
  role?: string;
  innerRef?: React.ForwardedRef<HTMLFormElement>;
}

class Form extends React.Component<Props, object> {
  render() {
    const { onSubmit, innerRef, ...otherProps } = this.props;
    return (
      <form
        {...otherProps}
        ref={innerRef}
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          onSubmit(event);
        }}
      />
    );
  }
}

export default React.forwardRef<HTMLFormElement, Props>((props, ref) => <Form innerRef={ref} {...props} />);
