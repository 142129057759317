import * as React from 'react';
import cn from 'classnames';

import { UiSize } from 'app/styleguide/styles/sizes';
import { DEFAULT_SIZE } from '../constants';

import css from './Checkbox.scss';

interface Props {
  id: string;
  className?: string;
  size?: UiSize;
  onClick?: (event: Event) => void;
  disabled?: boolean;
  checked?: boolean;
}

const Checkbox = ({ size = DEFAULT_SIZE, id, className, onClick, checked, ...otherProps }: Props) => {
  let domNode;
  const wrappedOnClick = event => {
    if (onClick) {
      onClick(event);
    }
    if (domNode) {
      domNode.blur();
    }
  };

  return (
    <div className={cn(css.wrapper, className)}>
      <input
        type="checkbox"
        id={id}
        className={cn(css.styledCheckbox, css[`size--${size}`])}
        onClick={wrappedOnClick}
        ref={ref => {
          domNode = ref;
        }}
        checked={checked}
        {...otherProps}
      />
      <label htmlFor={id} className={css.styledLabel} />
    </div>
  );
};

export default Checkbox;
