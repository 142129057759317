import * as React from 'react';
import { Combobox } from '@headlessui/react';
import cn from 'classnames';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

type OptionValue = {
  key: string;
  label: string;
};

interface Props {
  value?: OptionValue;
  name?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  withCheckMark?: boolean;
  selectedVal?: OptionValue;
}

const ComboboxOption = ({ ...props }: Props) => {
  const currentKey = props.value.key;
  const selectedValKey = props.selectedVal.key;
  return (
    <Combobox.Option
      value={props.value}
      disabled={props.disabled}
      className={({ active, disabled }) =>
        cn(
          'relative cursor-default select-none py-2 pl-3 pr-9',
          active ? 'bg-blue-50 text-default' : 'text-default',
          disabled ? '!text-gray-500' : '',
        )
      }
    >
      {({ selected }) => (
        <>
          <span
            data-cy={`${currentKey}`}
            className={cn('block text-sm font-medium', selected && 'font-semibold')}
          >
            {props.name}
          </span>
          {(selected || currentKey === selectedValKey) && props.withCheckMark && (
            <span className={cn('absolute inset-y-0 right-0 flex items-center pr-4', 'text-green-500')}>
              <CheckCircleIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          )}
        </>
      )}
    </Combobox.Option>
  );
};

export default ComboboxOption;
