import { Combobox as HeadlessUICombobox, Transition } from '@headlessui/react';
import cn from 'classnames';
import React, { Fragment } from 'react';
import AbstractInput from 'styleguide/components/forms/AbstractInput/AbstractInput';
import { IconClose } from 'styleguide/icons';

import Option from './Option';

type OptionType = {
  key: string;
  label: string;
  [propName: string]: string;
};

interface OptGroup {
  label: string;
  options: Array<Record<'key' | 'label', string>>;
}

interface Props {
  options?: OptionType[];
  valueGroups?: OptGroup[];
  Tooltip?: React.ReactNode;
  onChange?: (val: OptionType) => void;
  withClear?: boolean;
  creatable?: boolean;
  isMulti?: boolean;
  className?: string;
  disabled?: boolean;
  inPlaceError?: boolean;
  value?: OptionType;
  withCheckMark?: boolean;
  Title?: React.ReactNode;
  defaultValueFirst?: boolean;
}

const Combobox = ({
  options = [],
  valueGroups = [],
  Tooltip = null,
  value,
  onChange,
  withClear = false,
  creatable = false,
  isMulti = false,
  withCheckMark = false,
  Title = null,
  defaultValueFirst = false,
  ...props
}: Props) => {
  const [selected, setSelected] = React.useState(value || (defaultValueFirst && options[0]) || null);
  const [selectedValues, setSelectedValues] = React.useState([value]);
  const [query, setQuery] = React.useState('');

  const getValueName = val => {
    if (!val) {
      return '';
    }
    if ('label' in val) {
      return val.label;
    }
    return val;
  };
  const filterValues = selectValues =>
    query === ''
      ? selectValues
      : selectValues.filter(filteredVal =>
          getValueName(filteredVal).toLowerCase().includes(query.toLowerCase()),
        );

  const filteredValueGroups = valueGroups.map(group => ({
    [getValueName(group)]: filterValues(group.options),
  }));

  const filteredData = filterValues(options);
  const onValueChanged = val => {
    setSelected(val);
    onChange(val);
  };

  const onMultipleValuesChanged = changedValues => {
    setSelectedValues(changedValues);
    onChange(changedValues);
  };

  const IsFilteredValueGroupsEmpty = () =>
    filteredValueGroups.every(group => Object.values(group)[0].length === 0);

  const removeValFromSelectedOptions = removedVal => {
    const newSelectedValues = selectedValues.filter(val => val !== removedVal);
    onMultipleValuesChanged(newSelectedValues);
  };
  const isEmptySearchResults = () => filteredData.length === 0 && IsFilteredValueGroupsEmpty();

  const clearSelected = () => {
    setQuery('');
    setSelected(null);
    onChange(null);
  };

  React.useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  return (
    <HeadlessUICombobox
      value={isMulti ? selectedValues : selected}
      onChange={isMulti ? onMultipleValuesChanged : onValueChanged}
      // multiple={isMulti}
    >
      <div className="relative">
        <HeadlessUICombobox.Button as="div">
          <AbstractInput
            value={value.key}
            disabled={props.disabled}
            component="Combobox"
            className={cn(props.className, withClear ? 'pr-10' : '', isMulti ? '!text-shades-0' : '')}
            onChange={event => setQuery(event.target.value)}
            displayValue={
              !isMulti
                ? val => getValueName(val)
                : vals =>
                    Array.isArray(vals) ? vals.map(val => getValueName(val)).join(', ') : getValueName(vals)
            }
            {...props}
          >
            {isMulti && selectedValues.length > 0 && (
              <div className="-mt-8 flex w-[98%] flex-wrap">
                {selectedValues.map(val => (
                  <div key={val.key} className="mb-2 ml-2 mr-2 rounded-md bg-blue p-1 text-shades-0">
                    <div className="flex">
                      <div className="text-sm">{val.label}</div>
                      <IconClose
                        className="ml-2 !h-2 !w-2 cursor-pointer"
                        color="white"
                        onClick={() => removeValFromSelectedOptions(val)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </AbstractInput>
        </HeadlessUICombobox.Button>

        {withClear && (
          <div
            className={`absolute right-7 top-1/2
            z-90 flex h-5 -translate-y-1/2 items-center border-r border-solid border-gray-300 pr-2`}
            onClick={clearSelected}
            onKeyDown={clearSelected}
            role="button"
            tabIndex={0}
          >
            <IconClose className="!h-2 !w-2 text-default" />
          </div>
        )}
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <HeadlessUICombobox.Options
            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-shades-0 py-1 text-base shadow-lg ring-1 
          ring-shades-100 ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {Title}
            <div className="'h-5 border-b border-solid border-gray-50">{Tooltip}</div>
            {filteredValueGroups.map(
              group =>
                Object.values(group)[0].length > 0 && (
                  <>
                    <div className="my-1">
                      <span className="pl-3 font-hvBold text-xs font-bold leading-4 text-gray-500">
                        {Object.keys(group)[0].toUpperCase()}
                      </span>
                    </div>

                    {Object.values(group)[0].map((val, index) => (
                      <Option
                        key={`${val.key}${index}`}
                        value={val}
                        name={val.outOfStock ? `${getValueName(val)} (out of stock)` : getValueName(val)}
                        disabled={val.outOfStock}
                        withCheckMark={withCheckMark}
                        selectedVal={selected}
                      />
                    ))}
                  </>
                ),
            )}
            {filteredData.map((val, index) => (
              <Option
                key={`${val.key}${index}`}
                value={val}
                name={val.outOfStock ? `${getValueName(val)} (out of stock)` : getValueName(val)}
                disabled={val.outOfStock}
                withCheckMark={withCheckMark}
                selectedVal={selected}
              />
            ))}
            {creatable && filteredData.length === 0 && IsFilteredValueGroupsEmpty() && query.length > 0 && (
              <Option value={{ label: query, key: query }} name={`Create ${query}`} />
            )}
            {!creatable && isEmptySearchResults() && query.length > 0 && (
              <div className="px-3 py-2 text-gray-500">Nothing found</div>
            )}
          </HeadlessUICombobox.Options>
        </Transition>
      </div>
    </HeadlessUICombobox>
  );
};

Combobox.Option = Option;

export default Combobox;
