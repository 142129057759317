import * as React from 'react';
import cn from 'classnames';
import DividedSection from './DividedSection/DividedSection';
import Divided from './Divided/Divided';
import Option from './Option/Option';
import DefaultOption from './DefaultOption/DefaultOption';
import AbstractInput, { AddonPlacement } from '../AbstractInput/AbstractInput';

import css from './Select.scss';
import OptGroup from './OptGroup/OptGroup';
import { UiColor } from 'styleguide/styles/colors';
import { UiSize } from 'styleguide/styles/sizes';

interface Props {
  value?: string | number;
  expanded?: boolean;
  className?: string;
  children: React.ReactNode;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  active?: boolean;
  disabled?: boolean;
  iconColor?: UiColor;
  iconPlacement?: AddonPlacement;
  size?: UiSize;
  selectIconColor?: UiColor;
}
const Select = ({
  value,
  expanded,
  className,
  active,
  disabled,
  selectIconColor = 'dark',
  size = 'md',
  ...otherProps
}: Props) => (
  <AbstractInput
    disabled={disabled}
    component="select"
    className={cn(
      css.Select,
      { [css.expanded]: expanded, [css.active]: active, [css.disabled]: disabled },
      className,
    )}
    value={active === false ? null : value}
    selectIconColor={selectIconColor}
    size={size}
    {...otherProps}
  />
);

Select.DividedSection = DividedSection;
Select.Divided = Divided;
Select.Option = Option;
Select.OptGroup = OptGroup;
Select.DefaultOption = DefaultOption;

export default Select;
