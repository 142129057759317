import * as React from 'react';
import cn from 'classnames';

import { UiSize } from 'styleguide/styles/sizes';

import css from './index.scss';

interface Props {
  value: string | number;
  checked: boolean;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: UiSize;
  withRedBorder?: boolean;
}

const RadioTab = ({ className, size = 'sm', children, withRedBorder, ...otherProps }: Props) => (
  <label
    className={cn(css.tab, className, {
      [css.disabled]: otherProps.disabled,
      [css.withRedBorder]: !!withRedBorder,
    })}
  >
    <input type="radio" className={css.input} {...otherProps} />
    <div
      className={cn(css.button, css[`size--${size}`], {
        [css.disabled]: otherProps.disabled,
      })}
    >
      {children}
    </div>
  </label>
);

export default RadioTab;
