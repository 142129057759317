import * as React from 'react';
import classNames from 'classnames';
import { UiSize } from 'styleguide/styles/sizes';

interface Props {
  placement: 'top' | 'right' | 'bottom' | 'left' | 'float';
  className?: string;
  asSpan?: boolean;
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  htmlFor?: string;
  normalFontWeight?: boolean;
  children?: React.ReactNode;
  inputWithIcon?: boolean;
  required?: boolean;
  invalid?: boolean;
  forTextArea?: boolean;
  inputSize?: UiSize;
}

const Label = ({
  placement,
  className,
  asSpan,
  hideOnMobile,
  hideOnTablet,
  normalFontWeight,
  inputWithIcon,
  required,
  invalid,
  forTextArea,
  inputSize,
  ...otherProps
}: Props) =>
  // This component is intended to be visual label for form elements
  // Sometimes visual label is different from actual clickable <label /> element
  // In such cases text label should be placed as <span /> inside actual <label />
  {
    const placementStyle = {
      top: 'px-0 pt-0 pb-4',
      right: 'py-0 pr-0 pl-4',
      bottom: 'px-0 pb-0 pt-4',
      left: 'py-0 pl-0 pr-4',
      float: `absolute text-xs peer-placeholder-shown:font-normal cursor-text pointer-events-none	
      text-neutral-900 duration-300 transform -translate-y-4 peer-focus:text-neutral-900 peer-focus:font-medium 
      top-2 z-1 origin-[0] bg-white px-1
      ${inputWithIcon ? `ml-8 peer-focus:ml-8` : `ml-2 peer-focus:ml-2`}
      ${inputSize === 'xs' ? 'ml-0' : ''}
      peer-placeholder-shown:text-gray-300 peer-placeholder-shown:-translate-y-1/2
      ${invalid ? `!text-red` : ``}
      ${required ? `after:content-['*'] after:ml-0.5 after:text-red peer-focus:after:content-['*']` : ``} 
      ${forTextArea ? `peer-placeholder-shown:top-[20px]` : `peer-placeholder-shown:top-1/2`}
      peer-focus:top-2 peer-focus:-translate-y-4 left-1`,
    };

    const Component = asSpan ? 'span' : 'label';

    return (
      <Component
        className={classNames(
          placement !== 'float' ? 'ml-[14px] inline-flex font-hvMedium text-xs leading-4' : '',
          placementStyle[placement],
          hideOnMobile ? '-md:hidden' : '',
          hideOnTablet ? '-lg:hidden' : '',
          normalFontWeight ? '!font-hvLite' : '',
          className,
        )}
        {...otherProps}
      />
    );
  };

export default Label;
