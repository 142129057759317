import * as React from 'react';
import css from './SelectInput.scss';
import Select from '../Select/Select';
import { Input } from '..';

interface Option {
  key: string | number;
  name: string;
}

interface OptGroup {
  name: string;
  options: Option[];
}

interface Props {
  value: string | number;
  placeholder: string;
  options?: Option[];
  optGroups?: OptGroup[];
  onChange: (val: string) => void;
}

interface State {
  activeControl: 'input' | 'select' | null;
}

class SelectInput extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      activeControl: null,
    };
  }

  render() {
    const { onChange, value, optGroups, options, placeholder } = this.props;
    const { activeControl } = this.state;

    return (
      <div className={css.selectInput}>
        <div className={css.section}>
          <Select
            active={activeControl === 'select'}
            onChange={e => {
              this.setState({ activeControl: 'select' });
              onChange(e.target.value);
            }}
            value={value}
          >
            {!!optGroups &&
              optGroups.map(group => (
                <Select.OptGroup label={group.name}>
                  {group.options.map(val => (
                    <Select.Option value={val.key}>{val.name}</Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            {!!options &&
              options.map((val, i) => (
                <Select.Option key={i} value={val.key}>
                  {val.name}
                </Select.Option>
              ))}
          </Select>
        </div>
        <div className={css.section}>
          <Input
            value={activeControl === 'input' ? value : ''}
            placeholder={placeholder}
            onChange={e => {
              this.setState({ activeControl: 'input' });
              onChange(e.target.value);
            }}
          />
        </div>
      </div>
    );
  }
}

export default SelectInput;
