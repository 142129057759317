import * as React from 'react';
import css from './Divided.scss';

interface Props {
  id?: string;
  name?: string;
  value?: string | number;
  children: React.ReactNode;
}

const Divided = ({ value, id, name, children }: Props) => (
  <div>
    <input value={value} id={id} name={name} type="hidden" />
    <div className={css.Divided}>{children}</div>
  </div>
);

export default Divided;
