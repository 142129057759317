import * as React from 'react';

import css from './DividedSection.scss';

interface Props {
  children: React.ReactNode;
}

const DividedSection = ({ ...props }: Props) => <div className={css.DividedSection}>{props.children}</div>;

export default DividedSection;
